import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core";
//import "../../../utils/styles/Mire.css";
//import "../../../utils/styles/header.css";
import QRCode from "react-qr-code";
import HiaFooter from "../../../widgets/HiaFooter";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import "./index.css";

const styles = (theme) => ({
  actions: {
    justifyContent: "center",
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
});

const Component = ({ actions, sessionInformation }) => {
  const [sessionId, setSessionId] = useState("");
  const [qrCodeData, setQrCodeData] = useState("");
  const [pollingInterval, setPollingInterval] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);
  const clientId = process.env.REACT_APP_CLIENT_ID;
  // Generate a unique session ID
  const generateSessionId = () => {
    return `${clientId}-${Math.random().toString(36).substring(2, 11)}`;
  };

  // Start a new session and get the QR code
  const createSession = async () => {
    const newSessionId = generateSessionId(); // Generate session ID
    try {
      console.log("Generating new session id : ", newSessionId);
      setSessionId(newSessionId);
      setQrCodeData(`https://app.hiasecure.com/sessions/?sessionId=${newSessionId}&serviceName=showroom`);

      // Set a timeout for session expiration (5 minutes)
      setTimeout(() => {
        setSessionExpired(true);
        clearInterval(pollingInterval); // Stop polling when session expires
      }, 300000); // 300000 ms = 5 minutes
    } catch (error) {
      console.error("Error starting session:", error);
    }
  };

  // Polling to check if an account is mapped to the session
  const getSessionInfo = async () => {
    if (!sessionId) return;
    /*
    actions.getSessionDetails(sessionId).then((session) => {
      console.log("session response :", session);
      if (session.data.login) {
        console.log("login with :", session.data.login);
        actions.validateLogin(session.data.login, "");
        setSessionInformation(session.data);
        clearInterval(pollingInterval); // Stop polling once session is found
      }
    });
    */
    actions.getSessionDetails(sessionId);
  };

  // Start polling once session is created
  useEffect(() => {
    if (sessionId && !sessionInformation) {
      console.log("Starting polling for session info...");
      const interval = setInterval(getSessionInfo, 5000); // Poll every 5 seconds
      setPollingInterval(interval);
      return () => clearInterval(interval); // Cleanup on component unmount
    }
  }, [sessionId, sessionInformation]);

  return (
    <div id="mire">
      <div className="mireContainer">
        <p className="mireTitle">Sign in</p>
        <Grid item xs={12}>
          <DialogContent>
            <Grid container justify="center">
              {!sessionId ? (
                <Button onClick={createSession} type="submit" color="primary">
                  Sign in
                </Button>
              ) : sessionExpired ? (
                <div>
                  <h3>Session expired. Please try again.</h3>
                </div>
              ) : (
                <div>
                  <div>
                    <h3>Scan the QR Code to authenticate</h3>
                    {qrCodeData && (
                      <div style={{ textAlign: "center" }}>
                        <QRCode value={qrCodeData} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Grid>
          </DialogContent>
        </Grid>
        <HiaFooter />
      </div>
    </div>
  );
};

export const enhance = compose(withStyles(styles));
export default enhance(Component);
